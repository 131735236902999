/**
 * 【表示用】Variantの「価格」から「商品1点あたりの費用」を計算
 */
;(($) => {

  const expr = '.js-variant-cost-calculate';

  const init = () => {
    $('body').on('change keyup', expr, onChanged);
    $(expr).trigger('change');
  };

  const onChanged = e => {
    const $element = $(e.currentTarget);
    const price = $element.val();
    const $for = $($element.data('for'));
    const rate = $element.data('rate');
    const cost = Math.floor(price * rate);

    $for.val(cost);
  };

  $(init);

})(jQuery);
