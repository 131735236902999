import axios from 'axios';
const feather = require('feather-icons');
import jQuery from 'jquery'
import Tagify from '@yaireo/tagify'

/**
 * 商品管理画面用JS
 */

/**
 * Popover
 * @require Tagify
 */
;(($) => {
  $(() => {
    $('[data-toggle="popover"]').popover();
  });
})(jQuery);


/**
 * ProductVariantオプション(create時)
 * @require Tagify
 */
;(($) => {
  // オプション
  const optionContainerExpr = '.js-create-variant-option-container';
  const optionExpr = '.js-variant-option';
  const optionNumberExpr = '.js-variant-option-number';
  const optionNameInputExpr = '.js-variant-option-name-input';
  const optionValueInputExpr = '.js-variant-option-value-input';
  const optionNamesExpr = '.js-create-variant-option-names';
  const addOptionContainerExpr = '.js-variant-add-option-container';
  const addOptionExpr = '.js-variant-add-option';
  const deleteOptionExpr = '.js-variant-delete-option';
  // Variant
  const variantsWrapperExpr = '.js-create-variant-variants-wrapper';
  const variantsContainerExpr = '.js-create-variant-variants-container';
  const variantExpr = '.js-variant-variant';
  const deleteVariantExpr = '.js-variant-delete-variant';
  // テンプレート
  const optionTemplateExpr = '#tmpl-variant-option';
  const variantTemplateExpr = '#tmpl-variant';

  const VARIANTS_LIMIT = 20;
  const optionLimit = 3;
  let $optionContainer, $optionNames, $variantsWrapper, $variantsContainer;
  let $optionTemplate, $variantTemplate;
  let currentCount = 0;

  const init = () => {
    $optionContainer = $(optionContainerExpr);
    if (!$optionContainer.length) return false;
    $optionNames = $(optionNamesExpr);
    if (!$optionNames.length) return false;
    $variantsWrapper = $(variantsWrapperExpr);
    if (!$variantsWrapper.length) return false;
    $variantsContainer = $variantsWrapper.find(variantsContainerExpr);
    if (!$variantsContainer.length) return false;
    $optionTemplate = $($(optionTemplateExpr).html());
    if (!$optionTemplate.length) return false;
    $variantTemplate = $($(variantTemplateExpr).html());
    if (!$variantTemplate.length) return false;

    $('body').
      on('click', addOptionExpr, onAddOptionClicked).
      on('click', deleteOptionExpr, onDeleteOptionClicked);
    $(addOptionExpr).trigger('click');
    $optionContainer.on('change', optionValueInputExpr, onTagifyChanged);
    $variantsContainer.on('click', deleteVariantExpr, onDeleteVariantClicked);
  };

  /**
   * ##### オプション系の処理 #####
   */

  // オプション - 「別のオプションを追加する」
  const onAddOptionClicked = e => {
    const $item = $optionTemplate.clone(true);
    currentCount++;
    _setupTagify($item);
    $optionContainer.append($item);
    _afterManipulated();
    return false;
  };

  // オプション - 「削除する」
  const onDeleteOptionClicked = e => {
    $(e.currentTarget).parents(optionExpr).remove();
    currentCount--;
    _afterManipulated();
    return false;
  };

  const onTagifyChanged = e => {
    const $element = $(e.currentTarget);

    _rebuildVariants();
  };

  const _setupTagify = $element => {
    const valueInput = $element.find(optionValueInputExpr)[0];
    new Tagify(valueInput);
  };

  const _afterManipulated = () => {
    let num = 1;
    if (currentCount >= optionLimit) {
      $(addOptionContainerExpr).hide();
    } else {
      $(addOptionContainerExpr).show();
    }
    if (currentCount <= 1) {
      $(deleteOptionExpr).hide();
    } else {
      $(deleteOptionExpr).show();
    }
    $optionContainer.find(optionExpr).each((key, element) => {
      const $element = $(element);
      $element.find(optionNumberExpr).html(num);
      num++;
    });
    _rebuildVariants();
  };

  /**
   * ##### Variant系の処理 #####
   */

  // Variant - 「削除」
  const onDeleteVariantClicked = e => {
    const $element = $(e.currentTarget);
    if (!confirm('削除してもよろしいですか？')) return false;
    $element.parents(variantExpr).remove();
    return false;
  };

  const _rebuildVariants = () => {
    const tmpParams = [];
    const optionNames = [];
    const hiddenOptionNameTemplate = '<input name="product[options][]" type="hidden">';
    const hiddenOptionTemplate = '<input name="product[variants][][options][]" type="hidden">';
    let params;
    let variants_length = 1;
    $optionContainer.find(optionExpr).each((key, element) => {
      const $element = $(element);
      const optionName = $element.find(optionNameInputExpr).val();
      const optionValue = $element.find(optionValueInputExpr).last().val();
      if (!optionName || !optionValue) return true;

      optionNames.push(optionName);
      tmpParams[key] = [];
      $.each(eval(optionValue), (k, o) => {
        tmpParams[key].push({ name: optionName, value: o.value });
      });
      variants_length *= tmpParams[key].length;
    });
    if (variants_length > VARIANTS_LIMIT) {
      alert(`バリエーションは${VARIANTS_LIMIT}件まで設定可能です。`);
      return false;
    }
    $variantsContainer.empty();
    $optionNames.empty();
    $variantsWrapper.show();
    if (!tmpParams.length) {
      $variantsWrapper.hide();
      return false;
    }
    $.each(optionNames, (key, val) => {
      const $hiddenOption = $(hiddenOptionNameTemplate);
      $hiddenOption.val(val);
      $optionNames.append($hiddenOption);
    });
    params = tmpParams[0];
    for (let i=0; i<tmpParams.length-1; i++) {
      const tmp = [];
      for (let j=0; j<params.length; j++) {
        for (let k=0; k<tmpParams[i+1].length; k++) {
          tmp.push([].concat(params[j], tmpParams[i+1][k]));
        }
      }
      params = tmp;
    }
    $.each(params, (key, optionValues) => {
      const $variant = $variantTemplate.clone(true);
      const $th = $variant.children('th').first();
      const $price = $variant.find('input[name="product[variants][][price]"]');
      const $cost = $variant.find('input[name="product[variants][][inventoryItem][cost]"]');
      $price.data('for', `#js-variant-cost-calculate_target_${key}`);
      $cost.attr('id', `js-variant-cost-calculate_target_${key}`);
      if (Array.isArray(optionValues)) {
        const optionLabel = [];
        $.each(optionValues, (k, val) => {
          const $hiddenOption = $(hiddenOptionTemplate);
          $hiddenOption.val(val.value);
          $th.append($hiddenOption);
          optionLabel.push(val.value);
        });
        $th.append(optionLabel.join(' / '));
      } else {
        let $hiddenOption = $(hiddenOptionTemplate);
        $hiddenOption.val(optionValues.value);
        $th.
          append($hiddenOption).
          append(optionValues.value);
      }
      $variantsContainer.append($variant);
    });
  };

  $(init);

})(jQuery);

/**
 * ProductVariant(edit時)
 * @require axios
 */
;(($) => {

  const VARIANTS_LIMIT = 20;

  const variantsWrapperExpr = '.js-edit-variant-variants-wrapper';
  const variantsContainerExpr = '.js-edit-variant-variants-container';
  const variantExpr = '.js-variant-variant';
  const variantInventoriesExpr = '.js-variant-variant-inventories';
  const deleteVariantExpr = '.js-variant-delete-variant';
  const addVariantExpr = '.js-variant-add-variant';
  const inventoriesTemplateExpr = '#tmpl-variant-inventories';
  let $wrapper, $container, $variantTemplate;

  const init = () => {
    $wrapper = $(variantsWrapperExpr);
    if (!$wrapper.length) return;
    $container = $wrapper.find(variantsContainerExpr);
    if (!$container.length) return;
    const $variants = $container.find(variantExpr);
    if (!$variants.length) return;
    const $inventoriesTemplate = $($(inventoriesTemplateExpr).html());
    if (!$inventoriesTemplate.length) return;

    $variantTemplate = $variants.first().clone();
    $variantTemplate.find('input').val('');
    $variantTemplate.find(deleteVariantExpr).attr('href', '');
    $variantTemplate.find(variantInventoriesExpr).html($inventoriesTemplate.clone());

    $wrapper.on('click', addVariantExpr, onAddClicked);
    $container.on('click', deleteVariantExpr, onDeleteClicked);
  };

  const onAddClicked = e => {
    const variantsLength = $container.find(variantExpr).length;
    const $variant = $variantTemplate.clone();
    const $price = $variant.find('input[name="product[variants][][price]"]');
    const $cost = $variant.find('input[name="product[variants][][inventoryItem][cost]"]');
    $price.data('for', `#js-variant-cost-calculate_target_${variantsLength}`);
    $cost.attr('id', `js-variant-cost-calculate_target_${variantsLength}`);
    if (variantsLength > VARIANTS_LIMIT) {
      alert(`バリエーションは${VARIANTS_LIMIT}件まで設定可能です。`);
      return false;
    }
    $container.append($variant);
    return false;
  }

  const onDeleteClicked = e => {
    const $element = $(e.currentTarget);
    if (!confirm('削除してもよろしいですか？')) return false;

    _deleteVariant($element);
    return false;
  };

  const _deleteVariant = async ($element) => {
    const $parent = $element.parents(variantExpr);
    const requestUrl = $element.attr('href');
    if (requestUrl != '') {
      let response;
      try {
        response = await axios.delete(requestUrl);
      } catch (error) {
        console.log(error.response.status);
        return;
      }
    }
    $parent.remove();
  };

  $(init);

})(jQuery);

/**
 * タグ選択UI
 * @require axios
 */
;(($) => {

  const expr = '.js-product-tag';
  const tagListExpr = '.js-product-tag-list';
  const tagItemExpr = '.js-product-tag-item';
  const addTagListExpr = '.js-product-add-tag-list';
  const addTagItemExpr = '.js-product-add-tag-item';
  const selectExpr = '.js-product-tag-select';
  const selectCheckboxExpr = '.js-product-tag-select-checkbox';
  const suggestExpr = '.js-product-tag-select-suggest';
  const tagItemTemplateExpr = '#tmpl-product-tag-item';
  const addTagItemTemplateExpr = '#tmpl-product-tag-add-item';
  const tagSelectCheckboxTemplateExpr = '#tmpl-product-tag-select-checkbox';
  let $wrapper, $tagList, $addTagList, $select;
  let $tagItemTemplate, $addTagItemTemplate, $tagSelectCheckboxTemplate;
  let endpoint;
  let tagMaster = [];

  const init = async () => {
    let $tmplate;
    const selectedTags = [];
    $wrapper = $(expr);
    if (!$wrapper.length) return;
    endpoint = $wrapper.data('endpoint');
    $tagList = $wrapper.find(tagListExpr);
    if (!$tagList.length) return;
    $addTagList = $wrapper.find(addTagListExpr);
    if (!$addTagList.length) return;
    $select = $wrapper.find(selectExpr);
    if (!$select.length) return;

    $tmplate = $(tagItemTemplateExpr);
    if (!$tmplate.length) return;
    $tagItemTemplate = $($tmplate.html());
    $tmplate = $(addTagItemTemplateExpr);
    if (!$tmplate.length) return;
    $addTagItemTemplate = $($tmplate.html());
    $tmplate = $(tagSelectCheckboxTemplateExpr);
    if (!$tmplate.length) return;
    $tagSelectCheckboxTemplate = $($tmplate.html());

    $tagList.find(tagItemExpr).each((key, element) => {
      const $element = $(element);
      selectedTags.push($element.data('name'));
    });

    await _loadMaster();
    _setupSelect(selectedTags);

    $('body').on('click', e => {
      if (!$select.is(e.target)
        && $select.has(e.target).length === 0
        && $('.show').has(e.target).length === 0
      ) {
        $select.removeClass('show');
      }
    });
    $select.on('change', selectCheckboxExpr, onCheckChanged);
    $tagList.on('click', tagItemExpr, onRemoveTagClicked);
    $addTagList.on('click', addTagItemExpr, onAddTagClicked);
    $wrapper.on('keyup', suggestExpr, onSuggestChanged);
  };

  const onCheckChanged = e => {
    const $element = $(e.currentTarget);
    const name = $element.val();
    let $tagItem;

    if ($element.prop('checked')) {
      $tagItem = $tagItemTemplate.clone();
      $tagItem.attr('data-name', name).prepend(name);
      $tagList.append($tagItem);
      feather.replace();
    } else {
      $tagItem = $tagList.find(`[data-name="${name}"]`);
      $tagItem.remove();
    }
    _rebuildAddTags();
  };

  const onRemoveTagClicked = e => {
    const $element = $(e.currentTarget);
    const name = $element.data('name');
    $select.find(`[value="${name}"]`).prop('checked', false).trigger('change');

    // disabled to removed item..
    if (confirm(name + " タグを削除しますか?") == true) {
      $("input[name='product[tags][]'][value='" + name + "']").prop('disabled', true);
      $element.remove()
    }
    return false;
  };

  const onAddTagClicked = e => {
    const $element = $(e.currentTarget);
    const name = $element.data('name');
    $select.find(`[value="${name}"]`).prop('checked', true).trigger('change');
    return false;
  };

  const onSuggestChanged = e => {
    const $element = $(e.currentTarget);
    const searchValue = $element.val();
    const $checkboxes = $select.find('[data-value]');
    const $notFound = $select.find('.not_found');
    if (searchValue == '') {
      $select.removeClass('show');
      return false;
    }
    $notFound.show();
    $checkboxes.hide();
    const $filtered = $checkboxes.filter(`[data-value*="${searchValue}"]`);
    if ($filtered.length) {
      $filtered.show();
      $notFound.hide();
    }
    $select.addClass('show');
    return false;
  };

  const _loadMaster = async () => {
    let response;
    try {
      response = await axios.get(endpoint);
    } catch (error) {
      console.log(error.response.status);
    }
    tagMaster = response.data.product_tags.map(product_tag => {
      return product_tag.name;
    });
  };

  const _setupSelect = (selected) => {
    tagMaster.forEach(tag => {
      const $check = $tagSelectCheckboxTemplate.clone();
      const checked = selected.includes(tag);
      $check.find(selectCheckboxExpr).
        val(tag).
        prop('checked', checked);
      $check.attr('data-value', tag);
      $check.find('span').html(tag);
      $select.append($check);
    });
    _rebuildAddTags();
  };

  const _rebuildAddTags = () => {
    const selected = [];
    $addTagList.empty();
    $($tagList[0]).find(tagItemExpr).each((key, element) => {
      const $element = $(element);
      selected.push($element.data('name'));
    });
    tagMaster.forEach(tag => {
      const $addTag = $addTagItemTemplate.clone();
      const checked = selected.includes(tag);
      if (selected.includes(tag)) return true;
      $addTag.
        attr('data-name', tag).
        html(tag);
      $addTagList.append($addTag);
    });
  };

  $(init);

})(jQuery);


/**
 * コレクション選択UI
 * @require axios
 */
 ;(($) => {

  const expr = '.js-product-collection';
  const collectionListExpr = '.js-product-collection-list';
  const collectionItemExpr = '.js-product-collection-item';
  const selectExpr = '.js-product-collection-select';
  const selectOpenerExpr = '.js-product-collection-select-opener';
  const selectCheckboxExpr = '.js-product-collection-select-checkbox';
  const hiddenListExpr = '.js-product-collection-hidden-list';
  const collectionItemTemplateExpr = '#tmpl-product-collection-item';
  const collectionSelectCheckboxTemplateExpr = '#tmpl-product-collection-select-checkbox';
  const initSelectedCollections = [];
  let $wrapper, $collectionList, $select, $hiddenList;
  let $collectionItemTemplate, $collectionSelectCheckboxTemplate;
  let endpoint;
  let collectionMaster = {};

  const init = async () => {
    let $tmplate;
    $wrapper = $(expr);
    if (!$wrapper.length) return;
    endpoint = $wrapper.data('endpoint');
    $collectionList = $wrapper.find(collectionListExpr);
    if (!$collectionList.length) return;
    $select = $wrapper.find(selectExpr);
    if (!$select.length) return;
    $hiddenList = $wrapper.find(hiddenListExpr);

    $tmplate = $(collectionItemTemplateExpr);
    if (!$tmplate.length) return;
    $collectionItemTemplate = $($tmplate.html());
    $tmplate = $(collectionSelectCheckboxTemplateExpr);
    if (!$tmplate.length) return;
    $collectionSelectCheckboxTemplate = $($tmplate.html());

    $collectionList.find(collectionItemExpr).each((key, element) => {
      const $element = $(element);
      initSelectedCollections.push($element.data('id'));
    });

    await _loadMaster();
    _setupSelect();

    $('body').on('click', e => {
      if (!$select.is(e.target)
        && $select.has(e.target).length === 0
        && $('.show').has(e.target).length === 0
      ) {
        $select.removeClass('show');
      }
    });
    $wrapper.on('click', selectOpenerExpr, e => {
      if ($select.hasClass('show')) {
        $select.removeClass('show');
      } else {
        $select.addClass('show');
      }
      return false;
    });
    $select.on('change', selectCheckboxExpr, onCheckChanged);
    $collectionList.on('click', collectionItemExpr, onCollectionClicked);

    _resetHiddenInputs();
  };

  const onCheckChanged = e => {
    const $element = $(e.currentTarget);
    const id = $element.val();
    let $collectionItem;
    if ($element.prop('checked')) {
      $collectionItem = $collectionItemTemplate.clone();
      $collectionItem.attr('data-id', id).prepend(collectionMaster[id]);
      $collectionList.append($collectionItem);
      feather.replace();
    } else {
      $collectionItem = $collectionList.find(`[data-id="${id}"]`);
      $collectionItem.remove();
    }
    _resetHiddenInputs();
  };

  const onCollectionClicked = e => {
    const $element = $(e.currentTarget);
    const id = $element.data('id');
    $select.find(`[value="${id}"]`).prop('checked', false).trigger('change');
    return false;
  };

  const _loadMaster = async () => {
    let response;
    try {
      response = await axios.get(endpoint);
    } catch (error) {
      console.log(error.response.status);
    }
    response.data.collections.forEach((collection) => {
      collectionMaster[collection.id] = collection.title;
    });
  };

  const _setupSelect = () => {
    for (const [id, title] of Object.entries(collectionMaster)) {
      const $check = $collectionSelectCheckboxTemplate.clone();
      $check.find(selectCheckboxExpr).
        val(id).
        prop('checked', initSelectedCollections.includes(id));
      $check.find('span').html(title);
      $select.append($check);
    }
  };

  const _resetHiddenInputs = () => {
    let willLeft = initSelectedCollections.slice();
    $hiddenList.empty();
    $select.find(`${selectCheckboxExpr}:checked`).each((key, element) => {
      const checkedId = $(element).val();
      const $input = $('<input name="product[collectionsToJoin][]" type="hidden">');
      willLeft = willLeft.filter(id => id != checkedId);
      $input.val(checkedId);
      $hiddenList.append($input);
    });
    willLeft.forEach(leftId => {
      const $input = $('<input name="product[collectionsToLeave][]" type="hidden">');
      $input.val(leftId);
      $hiddenList.append($input);
    });
  };

  $(init);

})(jQuery);
