/**
 * CKEditorセットアップ
 * 使用したいtextarea要素にクラス名 js-cktext-area を設定する
 * @require CKEditor
 */
;(($) => {
  const textareaClass = 'js-cktext-area';
  const init = () => {
    CKEDITOR.replaceAll(_replace);
  };
  const _replace = (textarea, config) => {
    if (!$(textarea).hasClass(textareaClass)) return false;
    const isReadOnly = $(textarea).hasClass("ckeditor-read-only");
    config.language = 'ja';
    config.toolbarGroups = [
      { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
      { name: 'editing', groups: [ 'find', 'selection', 'spellchecker' ] },
      { name: 'links' },
      { name: 'insert' },
      { name: 'forms' },
      { name: 'tools' },
      { name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
      { name: 'others' },
      '/',
      { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
      { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi' ] },
      { name: 'styles' },
      { name: 'colors' },
      { name: 'about' }
    ];
    config.removeButtons = 'Underline,Subscript,Superscript,Image';
    config.format_tags = 'p;h1;h2;h3;pre';
    config.removeDialogTabs = 'image:advanced;link:advanced';
    config.readOnly = isReadOnly;
  };
  $(init);
})(jQuery);
