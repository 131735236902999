import axios from 'axios';
import jQuery from 'jquery'
import 'jquery-ui/ui/widgets/sortable';

/**
 * ドラッグ & ドロップ画像アップロード
 * @require axios
 */
;(($) => {
  const expr = '.js-drop-uploader-drop-area';
  const fascadeExpr = '.js-drop-uoloader-fascade';
  const thumbnailExpr = '.js-drop-uploader-thumbnail';
  const thumbnailSrcExpr = '.js-drop-uploader-thumbnail-src';
  const thumbnailContainerExpr = '.js-drop-uploader-thumbnail-container';
  const thumbnailTemplateExpr = '#tmpl-drop-uploader-thumbnail-container';
  const thumbnailCheckboxExpr = '.js-bulk-img-checkbox-child';
  let uploadUrl = '';
  let $dropArea, $thumbnailContainer, $fascade;
  let $thumbnailTemplate;
  let progress = false;

  let uploadsQuantity = 0;
  const UPLOADS_QUANTITY_LIMIT = 20;

  const init = () => {
    $dropArea = $(expr);
    if (!$dropArea.length) return;
    uploadUrl = $dropArea.data('upload-url');
    $thumbnailContainer = $dropArea.find(thumbnailContainerExpr);
    if (uploadUrl == null) return;
    $thumbnailTemplate = $($(thumbnailTemplateExpr).html());

    $fascade = $dropArea.find(fascadeExpr);
    $('body').
      on('dragenter', expr, onDragEntered).
      on('dragover', expr, false).
      on('drop', expr, onDropped);
    $fascade.on('dragleave', onDragLeft);

    _updateUploadsQuantity();
  };

  const onDragEntered = e => {
    $fascade.show();
    return false;
  };

  const onDragLeft = e => {
    if (!$dropArea.hasClass('no-images')) {
      $fascade.hide();
    }
    return false;
  };

  const onDropped = e => {
    $fascade.hide();
    const transferedFiles = e.originalEvent.dataTransfer.files;
    if (!transferedFiles.length) return false;
    const q = uploadsQuantity + transferedFiles.length;
    if (q > UPLOADS_QUANTITY_LIMIT) {
      alert(`画像は${UPLOADS_QUANTITY_LIMIT}枚までアップロード可能です。`);
      return false;
    }
    if (progress) {
      alert('アップロード完了までお待ちください。');
      return false;
    }
    progress = true;

    _listUpload(transferedFiles);
    return false;
  };

  const _listUpload = async (transferedFiles) => {
    let file, formData;
    const $thumbnails = [];
    $.each(transferedFiles, (k, file) => {
      const $thumbnail = $thumbnailTemplate.clone();
      const $img = $thumbnail.find('img');
      const fileReader = new FileReader();
      fileReader.onload = () => {
        $img.attr('src', fileReader.result);
      };
      fileReader.readAsDataURL(file);
      $thumbnails.push($thumbnail);
      $thumbnailContainer.append($thumbnail);
    });
    for (let i=0; i<transferedFiles.length; i++) {
      let $thumbnail, $imgSrcInput;
      file = transferedFiles[i];
      $thumbnail = $thumbnails[i];
      $imgSrcInput = $thumbnail.find(thumbnailSrcExpr);
      formData = new FormData();
      formData.append('file', file);
      try {
        const response = await axios.post(uploadUrl, formData);
        $imgSrcInput.val(response.data.resourceUrl);
        $thumbnail.removeClass('progress');
        $thumbnail.find(thumbnailCheckboxExpr).trigger('change');
      } catch (error) {
        $thumbnail.html('エラー');
        console.log(error.response.status);
      }
    }
    $dropArea.removeClass('no-images');
    progress = false;
    _updateUploadsQuantity();
  };

  const _updateUploadsQuantity = () => {
    uploadsQuantity = $thumbnailContainer.find(thumbnailExpr).length;
  };

  $(init);

})(jQuery);

/**
 * 画像アクション挙動
 */
;(($) => {

  const expr = '.js-drop-uploader-drop-area';
  const parentExpr = '.js-drop-uploader-thumbnail';
  const checkboxExpr = '.js-drop-uploader-thumbnail-check';

  const init = () => {
    const $dropArea = $(expr);
    if (!$dropArea.length) return;
    $dropArea.on('change', checkboxExpr, onChange);
  };

  const onChange = e => {
    const $element = $(e.currentTarget);
    const $parent = $element.parents(parentExpr);
    if ($element.prop('checked')) {
      $parent.addClass('selected');
      return true;
    }
    $parent.removeClass('selected');
    return true;
  };

  $(init);

})(jQuery);

/**
 * 「まとめてチェック」関連
 * parentsExpr      チェックボックス「まとめてチェック」のセレクタ
 * childrenExpr     アイテム個別チェックボックスのセレクタ
 * showCheckedExpr  1つ以上チェックされている時に表示させる要素のセレクタ
 *                  チェック0の場合は非表示
 * hideCheckedExpr  1つ以上チェックされている時に非表示させる要素のセレクタ
 *                  チェック0の場合は表示
 * ※ すべての要素は、wrapperExpr要素内に入っている必要がある
 */
 ;(($) => {
  const wrapperExpr = '.js-bulk-img-checkbox-container';
  const parentsExpr = '.js-bulk-img-checkbox';
  const childrenExpr = '.js-bulk-img-checkbox-child';
  const showCheckedExpr = '.js-bulk-img-checkbox-show';
  const hideCheckedExpr = '.js-bulk-img-checkbox-hide';
  let $wrapper, $parents;
  let $showChecked, $hideChecked;
  let existsChecked = false;

  const init = () => {
    $wrapper = $(wrapperExpr);
    if (!$wrapper.length) return;
    $parents = $wrapper.find(parentsExpr);
    if (!$parents.length) return;
    const $children = $wrapper.find(childrenExpr);

    $showChecked = $(showCheckedExpr);
    $hideChecked = $(hideCheckedExpr);

    $wrapper.
      on('change', parentsExpr, onParentChanged).
      on('change', childrenExpr, onChildChanged);

    switchLinkedElements();
  };

  const onParentChanged = e => {
    const $element = $(e.currentTarget);
    const checked = $element.prop('checked');
    existsChecked = checked;
    $parents.each((key, element) => {
      $(element).prop('checked', checked);
    });
    $wrapper.find(childrenExpr).each((key, element) => {
      $(element).prop('checked', checked).trigger('change');
    });
    switchLinkedElements();
    return false;
  };

  const onChildChanged = e => {
    const $children = $wrapper.find(childrenExpr);
    const denominator = $children.length;
    const numerator = $children.filter(':checked').length;
    existsChecked = true;
    if (numerator < denominator) {
      $parents.each((key, element) => {
        $(element).prop('checked', false);
      });
      if (numerator < 1) {
        existsChecked = false;
      }
    } else {
      $parents.each((key, element) => {
        $(element).prop('checked', true);
      });
    }
    switchLinkedElements();
    return false;
  };

  const switchLinkedElements = () => {
    const $children = $wrapper.find(childrenExpr);
    if (!$children.length) {
      $showChecked.hide();
      $hideChecked.hide();
      return;
    }
    if (existsChecked) {
      $showChecked.show();
      $hideChecked.hide();
      return;
    }
    $showChecked.hide();
    $hideChecked.show();
  };

  $(init);

})(jQuery);

/**
 * 画像アクション挙動
 */
 ;(($) => {

  const expr = '.js-drop-uploader-drop-area';
  const parentExpr = '.js-drop-uploader-thumbnail';
  const checkboxExpr = '.js-image-delete-btn';
  const fascadeExpr = '.js-drop-uoloader-fascade';
  const thumbnailImageExpr = '.thumbnail-image';
  let $dropArea, $fascade;

  const init = () => {
    const $dropArea = $(expr);
    if (!$dropArea.length) return;
    $dropArea.on('click', checkboxExpr, onChange);
  };

  const onChange = e => {
    if (!window.confirm('削除してもよろしいですか?(元に戻すことはできません)')) {
      return false;
    }
    $dropArea = $(expr);
    const $element = $(e.currentTarget);
    const $parent = $element.parents(parentExpr);
    const requestUrl = $element.data('url');
    const token = document.querySelector('meta[name="csrf-token"]') ? document.querySelector('meta[name="csrf-token"]').getAttribute('content') : "";
    if (requestUrl) {
      try {
        axios.defaults.headers.common = {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-TOKEN' : token
        };
        axios.delete(requestUrl);
      } catch (error) {
        console.error(error);
      }
    }
    $parent.remove();
    const thumbnailCount = $dropArea.find(thumbnailImageExpr);
    if (thumbnailCount.length <= 0) {
      $dropArea.addClass('no-images');
      $fascade = $dropArea.find(fascadeExpr);
      $fascade.show();
    }
    return true;
  };
  $(init);
})(jQuery);

/**
 * アップロード画像のSortable
 * @require jquery-ui sortable
 */
;(($) => {
  const expr = '.js-drop-uploader-thumbnail-container';
  const init = () => {
    $(expr).sortable();
  };
  $(init);
})(jQuery);
