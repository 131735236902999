
// es modules are recommended, if available, especially for typescript
import flatpickr from "flatpickr";
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index';
import { Japanese } from "flatpickr/dist/l10n/ja"

// for product stock calender.. etc.
const elms = document.querySelectorAll(".js-year-month-picker")
elms.forEach(function (elm) {
  flatpickr(elm, {
    locale: Japanese,
    minDate: elm.dataset.minDate,
    maxDate: elm.dataset.maxDate,
    plugins: [
      new monthSelectPlugin({
        // shorthand: true, //defaults to false
        dateFormat: "Y年m月", //defaults to "F Y"
        // altFormat: "F Y年", //defaults to "F Y"
        // theme: "dark" // defaults to "light"
      })
    ]
  });
})
