/**
 * チェック状態で要素の表示/非表示
 * expr  チェックボックスのセレクタ
 *       data-show-target="セレクタ" チェック時に表示する要素のセレクタ（アンチェック時は非表示）
 *       data-hide-target="セレクタ" チェック時に非表示表示する要素のセレクタ（アンチェック時は表示）
 */
;(($) => {
  const expr = '.js-toggle-checkbox';

  const init = () => {
    $('body').on('change', expr, onChange);
    $(expr).trigger('change');
  };

  const onChange = e => {
    const $element = $(e.currentTarget);
    const showTarget = $element.data('show-target');
    const hideTarget = $element.data('hide-target');

    if ($element.prop('checked')) {
      if (showTarget != null) $(showTarget).show();
      if (hideTarget != null) $(hideTarget).hide();
      return false;
    }
    if (showTarget != null) $(showTarget).hide();
    if (hideTarget != null) $(hideTarget).show();
    return false;
  };

  $(init);

})(jQuery);

// for 日別在庫管理を行う checkbox field..
;(($) => {
  const expr = '.js-toggle-checkbox-reverse-zaiko-by-shipping_date';

  const init = () => {
    $('body').on('click', expr, onClick);
    $('body').on('change', expr, onChange);
    $(expr).trigger('change');
  };

  const onClick = e => {
    const $element = $(e.currentTarget);

    // when edit, prevent change
    if (e.currentTarget.readOnly) {
      e.preventDefault();
      return;
    }
    if (!$element.prop('checked') && !confirm("お客様が到着日を指定出来なくなります。チェックを外す場合は、商品編集画面の配送ルールの欄に、到着指定日が不可の旨、必ずご記載ください。")) {
      e.preventDefault();
      return;
    }
  };

  const onChange = e => {
    const $element = $(e.currentTarget);
    const showTarget = $element.data('show-target');
    const hideTarget = $element.data('hide-target');
    console.log(this)

    if ($element.prop('checked')) {
      if (showTarget != null) $(showTarget).hide();
      if (hideTarget != null) $(hideTarget).show();

      $("#js-toggle-checkbox-inventory-tracking2").hide();
    } else {
      if (showTarget != null) $(showTarget).show();
      if (hideTarget != null) $(hideTarget).hide();

      process2nd();
    }

    function process2nd() {
      // 2nd checkbox
      $('#product_variants_tracked').trigger('change');
    }
  };

  $(init);

})(jQuery);
